import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import { VueQueryPlugin } from '@tanstack/vue-query'

import "vuetify/styles";
import "vue-toast-notification/dist/theme-bootstrap.css";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import "./style.css";

// MDI
import "@mdi/font/css/materialdesignicons.css";
// Bootstrap (Optional, for global styling)
// import "bootstrap/dist/css/bootstrap.min.css";

// Create Vuetify instance
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi", // Use MDI icons by default
  },
});

// Create Vue app instance
const app = createApp(App);

const pinia = createPinia();

// Mount the app with router, Vuex, and Vuetify
app
  .use(router) // Use Vue Router for routing
  .use(vuetify) // Use Vuetify for UI components and styles
  .use(pinia) // Use Pinia for state maneger
  .use(VueQueryPlugin, { // User Vue-Query for data fetching
    queryClientConfig: {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: 1
        }
      }
    }
  })
  .mount("#app"); // Mount the app to the DOM
