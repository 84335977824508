import axios from "axios";
import { useAuthStore } from "@/stores/auth";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL || "http://localhost:5000/api",
  timeout: 10000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore();

    // Add temporary token for MFA endpoints if available
    if (config.url?.includes("/mfa/") && authStore.tempToken) {
      config.headers.Authorization = `Bearer ${authStore.tempToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    const { data } = response;

    // if success true, return all json response
    if (data.success === true) {
      return data;
    }

    // else if success = false but still got 2xx http response, return rejection promise
    return Promise.reject({
      message: data.message || "Unknown error occurred",
      metadata: data.metadata,
    });
  },
  // IF http response is not 2xx
  (error) => {
    const { response } = error;

    const errorMessage = response?.data?.message || "Network error occurred";
    const errorMetadata = response?.data?.metadata || {};
    const status = response?.status;

    // if (status === 401 && response.config.url?.includes('/mfa/')) {
    //   const authStore = useAuthStore()
    //   authStore.tempToken = null
    // }

    return Promise.reject({
      message: errorMessage,
      metadata: errorMetadata,
      status,
    });
  }
);

export default axiosInstance;
