import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/loginPage.vue"),
    meta: { requiresGuest: true }, // Only accessible when not authenticated
  },
  {
    path: "/otp",
    name: "OTP",
    component: () => import("@/views/multifactorPage.vue"),
    meta: { requiresTempToken: true }, // Requires temp token from login
  },
  {
    path: "/verification",
    name: "Verification",
    component: () => import("@/views/verificationPage.vue"),
    meta: { requiresTempToken: true }, // Requires temp token from login
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/homePage.vue"),
    meta: { requiresAuth: true, requiresRole: "business" },
  },
  {
    path: "/business",
    name: "Business",
    component: () => import("@/views/businessPage.vue"),
    meta: { requiresAuth: true, requiresRole: "admin" },
  },
  {
    path: "/setting",
    name: "Setting",
    component: () => import("@/views/settingPage.vue"),
    meta: { requiresAuth: true, requiresRole: "admin" },
  },
  {
    path: "/business/summary/:id",
    name: "Summary",
    component: () => import("@/views/businessSummaryPage.vue"),
    meta: { requiresAuth: true, requiresRole: "admin" },
  },
  {
    path: "/business/update/:id",
    name: "Update",
    component: () => import("@/views/businessUpdatePage.vue"),
    meta: { requiresAuth: true, requiresRole: "admin" },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/notFoundPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();


  // Check authentication status if not in MFA flow
  if (!authStore.tempToken && !authStore.isOnOTPFlow) {
    try {
      await authStore.checkAuth();
    } catch (error) {
      // Ignore error - user might not be authenticated
    }
  }

  // Handle MFA routes (OTP and verification)
  if (to.meta.requiresTempToken) {
    if (!authStore.tempToken) {
      // No temp token, redirect to login
      return next({ name: "Login" });
    }
    return next();
  }

  // Handle authenticated routes
  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    return next({ name: "Login" });
  }

  // Handle guest routes (like login)
  if (to.meta.requiresGuest && authStore.isAuthenticated) {
    return next({ path: "/" });
  }
  if (to.meta.requiresRole) {
    const userRole = authStore.user.role; // Assuming 'role' is saved in the auth store

    if (userRole !== to.meta.requiresRole) {
      /**
       * Rule 1
       * 
       * If Admin and is authenticated and path == "/" then redirect to /companies
       */
      if (userRole === "admin") return next({ path: "/business" });
      // If the user doesn't have the required role, redirect to a default or forbidden page
      return next({ name: "NotFound" }); // Or redirect to an error page
    }
  }

  next();
});

export default router;
