import { defineStore } from "pinia";
import { ref } from "vue";
import authService from "@/services/auth.service";

export const useAuthStore = defineStore("auth", () => {
  const user = ref(null);
  const business = ref(null);
  const isAuthenticated = ref(false);
  const isOnOTPFlow = ref(false);
  const tempToken = ref(null);
  const mfaMethods = ref(null);
  const usedMethod = ref(null);
  const mfaStatus = ref({
    email: false,
    googleAuth: false
  })

  async function login(email, password) {
    try {
      const response = await authService.login(email, password);
      tempToken.value = response.data.tempToken;
      mfaMethods.value = response.data.mfaMethods;
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function initiateMFA(method) {
    try {
      const response = await authService.initiateMFA(method, tempToken.value);
      usedMethod.value = method;
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function verifyMFA(code) {
    try {
      const response = await authService.verifyMFA(
        usedMethod.value,
        code,
        tempToken.value
      );
      // MFA successful - now we have httpOnly cookies set
      user.value = response.data;
      isAuthenticated.value = true;
      isOnOTPFlow.value = true;
      tempToken.value = null;
      mfaMethods.value = null;
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function statusMFA() {
    try {
      const { email, googleAuth } = (await authService.statusMFA()).data
      mfaStatus.value = {
        email: email.enabled,
        googleAuth: googleAuth.enabled
      }
    } catch (error) {
      throw error
    }
  }

  async function logout() {
    try {
      await authService.logout();
    }
    finally {
      user.value = null;
      isAuthenticated.value = false;
      isOnOTPFlow.value = true;
      tempToken.value = null;
      mfaMethods.value = null;
    }
  }

  async function getBusiness() {
    try {
      const response = await authService.getBusiness();
      business.value = response.data;
      return business;
    } catch (error) {
      business.value = null;
      return { name: null, phoneNumber: null };
    }
  }

  async function checkAuth() {
    if (isOnOTPFlow.value) {
      return { user: user.value, isAuthenticated: isAuthenticated.value };
    }

    try {
      const response = await authService.getProfile();
      user.value = response.data;
      isAuthenticated.value = true;
      isOnOTPFlow.value = true;
      return response;
    } catch (error) {
      user.value = null;
      isAuthenticated.value = false;
      isOnOTPFlow.value = true;
      tempToken.value = null;
      mfaMethods.value = null;
      return { user: null, isAuthenticated: false };
    }
  }

  async function companyList(limit, search) {
    try {
      const response = await authService.listBusiness(limit, search);
      return response;
    } catch (error) {
      throw error;
    }
  }


  async function googleAuthSetup() {
    try {
      return (await authService.googleAuthSetup()).data
    } catch (error) {
      throw error
    }
  }

  async function googleAuthVerify(payload) {
    try {
      return (await authService.googleAuthVerify(payload)).data
    } catch (error) {
      throw error
    }
  }

  async function googleAuthDisable(payload) {
    try {
      return (await authService.googleAuthDisable(payload)).data
    } catch (error) {
      throw error
    }
  }

  async function changePassword(payload) {
    try {
      return await authService.changePassword(payload)
    } catch (error) {
      throw error
    }
  }

  return {
    user,
    isAuthenticated,
    isOnOTPFlow,
    tempToken,
    mfaMethods,
    business,
    mfaStatus,
    login,
    initiateMFA,
    verifyMFA,
    statusMFA,
    logout,
    checkAuth,
    getBusiness,
    companyList,
    googleAuthSetup,
    googleAuthVerify,
    googleAuthDisable,
    changePassword,
  };
});
