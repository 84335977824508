import api from "./axiosInstance";

class AuthService {
  async login(email, password) {
    return api.post("auth/login", { email, password });
  }
  async initiateMFA(method, tempToken) {
    return api.post(
      "auth/mfa/initiate",
      { method },
      {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      }
    );
  }
  async verifyMFA(method, code, tempToken) {
    return api.post(
      "auth/mfa/verify",
      { method, code },
      {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      }
    );
  }

  async statusMFA() {
    return api.get("/mfa/status")
  }

  async googleAuthSetup() {
    return api.post("/mfa/google-auth/setup")
  }

  async googleAuthVerify(payload) {
    return api.post("/mfa/google-auth/verify", payload)
  }

  async googleAuthDisable(payload) {
    return api.post("/mfa/google-auth/disable", payload)
  }

  async changePassword(payload) {
    return api.post("/changePassword", payload)
  }

  async getProfile() {
    return api.get("/profile");
  }
  async getBusiness() {
    return api.get("/business");
  }
  async logout() {
    return api.get("/auth/logout");
  }
}

export default new AuthService();
